.gradient-border {
  --borderWidth: 2px;
  position: relative;
  z-index: 1;
}

.gradient-border:after {
  content: '';
  position: absolute;
  z-index: -1;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #D71FFF, #3BD8FF,#D71FFF, #3BD8FF);
  -webkit-animation: animatedgradient 5s linear infinite;
          animation: animatedgradient 5s linear infinite;
  background-size: 300% 300%;
}

@-webkit-keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
