// Margin
.margin-auto {
  margin: auto;
}

//Pointer Events
.pointer-events-none {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}
