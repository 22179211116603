@import './scss-variables.scss';

.app-button {
  position: relative;
  border: none;
  background: var(--ion-color-primary);
  cursor: pointer;
  font-size: 18px;
  padding: 8px;
  color: white;
  // border: 1px solid var(--ion-color-primary);
  &:focus {
    outline: none;
  }
  &:hover {
    background: var(--ion-color-tertiary);
    color: white;
    // border: 1px solid var(--ion-color-primary);
  }

  &.primary {
    background: var(--ion-color-tertiary);
    color: var(--ion-color-tertiary-contrast);
    border: none;
    &:hover {
      background: white;
      color: var(--ion-color-tertiary);

      i, span {
        color: var(--ion-color-tertiary);
      }
    }
  }
  &.gray {
    background: $button-gray;
    color: var(--ion-color-dark);
    border: 1px solid $button-gray;
    &:hover {
      background: $white;
      color: var(--ion-color-primary);
      border: 1px solid var(--ion-color-primary);
    }
  }
  &.light {
    background: rgba($color: var(--ion-color-secondary-rgb), $alpha: 1.0);
    color: white;
    &:hover {
      filter: brightness(120%);
    }
  }
  &.transparent {
    background: transparent;
    color: var(--ion-color-tertiary);
    &:hover {
      border-color: var(--ion-color-tertiary) !important;
    }
  }
  &:disabled, &[disabled], &.disabled {
    background: $disabled-gray!important;
    color: $disabled-border!important;
    border: 1px solid $disabled-border!important;
    &:hover {
      background: $disabled-gray!important;
      color: $disabled-border!important;
      border: 1px solid $disabled-border!important;
    }
  }

  &.lg-padding {
    padding: 8px 28px;
  }
  &.width-100 {
    width: 100%;
  }
}

//----------------LINK------------------//@at-root
.app-link {
  text-decoration: none;
}

//----------------buttons------------------//
.transparent-with-outline-style {
  
  z-index: 1;
  &:hover {
    color: $white;

    &:before {
      background: linear-gradient(to left, #6501FF, #0177FF);
    }
  }
  &:disabled, &[disabled], &.disabled {
    &:after {
      background: $text-light-grey;
    }
    &.reversed:after {
      background: $text-light-grey;
    }
    &:hover {
      color: $white;

      &:before {
        background: var(--ion-color-primary) !important;
      }
    }
  }

  /* This controls the background of the button before hover */
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: var(--ion-color-primary);
    z-index: -1;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #6501FF, #0177FF); /* adding this because the buttons are so ugly! */
  }

  &:after {
    position: absolute;
    content: '';
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background: linear-gradient(to right, #D71FFF, #3BD8FF);
    z-index: -2;
    top: -2px;
    left: -2px;
  }

  &.reversed {
    &:after {
      background: linear-gradient(to right, #3BD8FF,#D71FFF);
    }
  }
}

.empower-button-container {
  z-index: 2;
}
.empower {
  flex-grow: 1;
  margin-right: 12px;
}

button {
  padding: 12px 20px;
  &:hover {
    color: white;
  }
}

.empower-button-style {
  background: linear-gradient(90deg, #6501FF, #0177FF);
  width: 100%;
  &:hover {
    background: linear-gradient(270deg, #6501FF, #0177FF);
    color: $white;
  }
}

.login-button {
  background: $active-color;
  border: 2px solid var(--ion-color-tertiary);
  margin-left: auto;
  margin-right: 8px;
  &:hover {
    color: $white;
    border: 2px solid var(--ion-color-tertiary);
    background: var(--ion-color-tertiary);
  }
}
