.d-flex {
  display: flex;
  transition: all 0.1s linear; //So resizing screen is a smoother experience 
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-one {
  flex: 1;
}
