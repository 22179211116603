@import '../scss/scss-variables.scss';

.modal-background {
  .mat-dialog-container {
    background: $modal-background;
    //padding-bottom: 10vh; // Just adding some padding on bottom so it has some breathing room
  }
}

.mat-button-wrapper {
  color: $text-light-grey;
}


// .small-screen {
//   .mat-dialog-container {
//     padding: 6px!important;
//     min-height: 400px; //fixing the capotcha slider glitch
//     overflow-y: auto; //So the scrollbar doesn't take up space on the screen
//   }
// }

@media screen and (max-width: 576px) {
  .small-screen {
    .mat-dialog-container {
      padding: 6px!important;
      min-height: 400px; //fixing the capotcha slider glitch
      overflow-y: auto; //So the scrollbar doesn't take up space on the screen
    }
  }
}
