@import './scss-variables.scss';

.icon {
  color: var(--ion-color-medium);
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    color: var(--ion-color-tertiary);
  }

  @include bp-lg {

    &:hover {
      color: $text-light-grey;
    }
  }
}

.icon-theme {
  color: var(--text-color);
}

.icon-white {
  color: white;
}

.icon-primary {
  color: var(--ion-color-primary);
}

.icon-light-grey {
  color: $text-light-grey;
}

.icon-bold {
  font-weight: bold;
}
