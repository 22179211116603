.app-coin {
  margin: 0 6px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  &.w-h-50 {
    width: 50px;
    height: 50px;
  }
}
