/*-----------Animations Variables-------------*/
//Just testing will remove animations later if not working

.fade-in-1 {
  opacity:0; //So the items are not in view on page load
  animation-timing-function: ease-in;

  animation: fadeIn 1s;
  animation-delay: 0s;

  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0s;

  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0s;

  -o-animation: fadeIn 1s;
  -o-animation-delay: 0s;

  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0s;
  animation-fill-mode: forwards; //So it stays after the animation is finished

}

.fade-in-2 {
  opacity:0; //So the items are not in view on page load
  animation-timing-function: ease-in;

  animation: fadeIn 1s;
  animation-delay: 0.15s;

  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.15s;

  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.15s;

  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.15s;

  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.15s;
  animation-fill-mode: forwards; //So it stays after the animation is finished

}

.fade-in-3 {
  opacity:0; //So the items are not in view on page load
  animation-timing-function: ease-in;

  animation: fadeIn 1s;
  animation-delay: 0.30s;

  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.30s;

  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.30s;

  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.30s;

  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.30s;
  animation-fill-mode: forwards; //So it stays after the animation is finished

}

.fade-in-4 {
  opacity:0; //So the items are not in view on page load
  animation-timing-function: ease-in;

  animation: fadeIn 1s;
  animation-delay: 0.45s;

  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.45s;

  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.45s;

  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.45s;

  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.45s;
  animation-fill-mode: forwards; //So it stays after the animation is finished
}

.fade-in-5 {
  opacity:0; //So the items are not in view on page load
  animation-timing-function: ease-in;

  animation: fadeIn 1s;
  animation-delay: 0.60s;

  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.60s;

  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.60s;

  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.60s;

  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.60s;
  animation-fill-mode: forwards; //So it stays after the animation is finished
}

.fade-in-6 {
  opacity:0;
  animation-timing-function: ease-in;

  animation: fadeIn 1s;
  animation-delay: 0.75s;

  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.75s;

  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.75s;

  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.75s;

  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.75s;
  animation-fill-mode: forwards; //So it stays after the animation is finished
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

//JUST TESTING
//Will remove this later if it does not work (above)

//EXTERNAL LINK ICON 
.external-link { //adds an external link icon to the end of any div or span that has this class 
  display: inline-block;
  height: 24px;
  width: 24px;
  &::after { //putting the link arrow 
    content: url('../../assets/images/hyperlink.png');
    transform: scale(0.11);
    width: 8px;
    height: 0px;
    display: inline-block;
  }
} // END EXTERNAL LINK


//VARIABLES FOR LEFT AND RIGHT THINGYS
.side-thing { //The thing on the side
  background-color:#121b29;
  vertical-align:top; //so the items align top
  width: 20vw;
  height:100%;
  display:inline-block; //to line the items up nicely 
}

.left-side { //The left thing
  border-right:6px solid #24334d;
}

.right-side { //The lright thing
  border-left:6px solid #24334d;
}

.middle-thing { //In rare cases when you need a middle thing
  background-color: #121b29;
  height: 100%;
  padding: 20px;
}

//LOGIN - ONBOARDING MESSGES + ARROWS 
.message { //message text 
  font-size: 22px;
}
#onboarding-arrow img {
  max-height: 140px; //So the arrow doesn't get too big 
}
#message-please-login { //Please login message
  display:inline-block;
  width: 100%;
  text-align: center; 
  padding-top:10%;
}
#wallet-arrow { //wallet onboarding arrow 
  display:none; 
}
#onboarding-arrow { //The arrow
  display:none;
  width: 15%;
  text-align: right;
  padding: 0px 2% 0 0;
}
@media only screen and (max-width: 992px) { //GENERAL MOBILE STUFF
  #onboarding-arrow { //So we can show the arrow now
    display:inline-block; //literally the best thing bester than display: flex; !!! 
  }
  #message-please-login { //Please login message 
    width: 85%;
  }
  #message-connect-wallet {
    padding-top: 15vh; //so its lower on the page
    display: block; 
  }
  #wallet-arrow { //wallet onboarding arrow 
    display:block; //Wow display block is so cool 
    text-align: right;
    width: 81%;
    padding-top: 15vh; //so its lower on the page
    min-height: 40vh; //seeing if it will maintain the same height
    img {
      transform: scaleY(-1) scaleX(1); //flipping the arrow
    }
  }
  #wallet-arrow { //wallet onboarding arrow 
    display:block; //Wow display block is so cool 
    text-align: right;
    width: 87%;
    padding-top: 14vh; //so its lower on the page
    position: fixed; //trying this out
    bottom: 70px;
    right: 30%; //so it lines up with the wallet icon in the bottom nav 
    img {
      transform: scaleY(-1) scaleX(1); //flipping the arrow
    }
  }
}//END MEDIA QUERY

//LOGIN - ONBOARDING PULSE ANIMATIONS + ICONS
.onboarding-animation {
  pointer-events:none; //trying to fix the issue of not being able to click through this 
}
.angled-scale { //apply this class to anything you want to warp in 3D, should be a Div OUTSIDE of what you want to warp
  -webkit-transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg); // Chrome, Safari, Opera 
  transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg); // Standard syntax 
  pointer-events:none; 
}
.pulse-animation { //apply this class to anything you want to pulsate
  pointer-events:none;
  width: 35px;
  height: 35px;
  text-align: center;
  color: white;
  border: none;
  border-radius: 50%;
  background: rgba(#ffc409, 0.20);
  //cursor: pointer;
  box-shadow: 0 0 0 0 rgba(#ffc409, 1.0);
  animation: pulse 1.50s infinite;
  -moz-animation: pulse 1.50s infinite;
  -webkit-animation: pulse 1.50s infinite;
}
// ========== animation frames for the pulse ==========
@keyframes pulse { //Keyframes for pulse animation 
  0% {transform: translate(0, 0px) scale(1.0);}
  70% {
    box-shadow: 0 0 0 50px rgba(#ffc409, 0);
    background: rgba(#ffc409, 0.50);
  }
    100% {
    box-shadow: 0 0 0 0 rgba(#ffc409, 0);
  }
} //end pulse animation frames
@-moz-keyframes pulse { //Keyframes for pulse animation 
  0% {transform: translate(0, 0px) scale(1.0);}
  70% {
    box-shadow: 0 0 0 50px rgba(#ffc409, 0);
    background: rgba(#ffc409, 0.50);
  }
    100% {
    box-shadow: 0 0 0 0 rgba(#ffc409, 0);
  }
} //end pulse animation frames
@-webkit-keyframes pulse { //Keyframes for pulse animation 
  0% {transform: translate(0, 0px) scale(1.0);}
  70% {
    box-shadow: 0 0 0 50px rgba(#ffc409, 0);
    background: rgba(#ffc409, 0.50);
  }
    100% {
    box-shadow: 0 0 0 0 rgba(#ffc409, 0);
  }
} //end pulse animation frames

// ========== animation frames for the bounce ==========
.bounce { //put this class on things you want to bounce up and down
  animation: bounce 1.50s infinite;
  -moz-animation: bounce 1.50s infinite;
  -webkit-animation: bounce 1.50s infinite;
}

@keyframes bounce { //Keyframes for bounce animation 
  0% {transform: translate(0, 0px);}
  70% {transform: translateY(15px);}
  100% {transform: translate(0, 0px);}
} //end bounce animation frames
@-moz-keyframes bounce { //Keyframes for bounce animation 
  0% {transform: translate(0, 0px);}
  70% {transform: translateY(15px);}
  100% {transform: translate(0, 0px);}
} //end bounce animation frames
@-webkit-keyframes bounce { //Keyframes for bounce animation 
  0% {transform: translate(0, 0px);}
  70% {transform: translateY(15px);}
  100% {transform: translate(0, 0px);}
} //end bounce animation frames

// ========================== END ONBOARDING ==========================

@media only screen and (max-width: 1000px) { //HIDING THE SIDE THINGYS specifically for the wallet page because it has 2 columns
  .side-thing {
    display:none;
  }
}//END MEDIA QUERY
//END VARIABLES FOR LEFT AND RIGHT THINGYS

/*-----------New Variables-------------*/
$header-height: 52px;
$border-style: 1px solid var(--ion-color-secondary);
$section-margin: 6px;
$inner-section-margin: 2px;

/*----------Font Sizes---------------*/
$font-size-sm: 12px;
$font-size-15: 15px;
$font-size-18: 18px; // have to change 16 to 14 everywhere
$font-size-20: 20px;
$font-size-m: 24px;
$font-size-lg: 36px;

/*-----------Colors-------------*/
$white: #FFFFFF;
$button-gray: #F2F2F2;

$modal-background: #121B29;

$disabled-gray: #1A2538;
$disabled-border: #485875;

$text-light-grey: #485875;
$text-bright-light-grey: #7887A2;
$button-background-shade-blue: #14273F;

$active-color: #24334D;
$circulation-reward: #1B273B;

$header-grey: #aab4c5;

/*--------------break points-------------------*/
//get width / 16px for correct em
$bp-1: 1400px;
$bp-xl: 1200px; // 1200px
$bp-lg: 992px; // 992px
$bp-md: 768px; // 768px
$bp-sm: 576px; // 576px
$bp-xs: 100%; // 0px

/*------------Animations---------------*/

.shake {
  transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    animation-name: shakeMe;
    animation-duration: .2s;
    animation-iteration-count: 5;
    animation-timing-function: linear;

    @keyframes shakeMe {
      0% {
        transform: translateX(0);
      }
      25% {
        transform: translateX(-5px);
      }
      50% {
        transform: translateX(5px);
      }
      75% {
        transform: translateX(-5px);
      }
      100% {
        transform: translateX(0);
      }
    }
}

@mixin animateIn {
  animation-name: animateIn;
  animation-duration: 200ms;
  animation-delay: var(--animation-order);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  @keyframes animateIn {
    0% {
      opacity: 0;
      transform: scale(0.6) translateY(-.8rem);
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin dropIn {
  animation-name: dropIn;
  animation-duration: 200ms;
  animation-delay: var(--animation-order);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  @keyframes dropIn {
    0% {
      opacity: 0;
      transform: translateY(-3rem);
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin bounce {
  animation-name: bounce;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  @keyframes bounce {
    0% {
      transform: translateY(0%);
    }
    50% {
      transform: translateY(20%);
    }
    100% {
      transform: translateY(0%);
    }
  }
}

@mixin fadeIn {
  animation-name: fadeIn;
  animation-duration: 400ms;
  animation-timing-function: ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0%;
    }
    to {
      opacity: 100%;
    }
  }
}

@mixin fade {
  animation: fade 0.8s ease;
    @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@mixin slide {
  animation: slide 0.5s linear forwards;
  @keyframes slide {
    0% {
      width: 0;
    }
    50% {
      width: 100%;
    }
    100% {
      left: 0%;
      width: 0%;
    }
  }
}

@mixin flip {
  animation: flip 0.3s linear;
  @keyframes flip {
    0% {
      transform: rotateY(-90deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }
}

@mixin shake {
  animations: shake 0.3s 0.3s linear !important;
  @keyframes shake {
    0% {
      transform: translateX(-3px);
    };
    25% {
      transform: translateX(3px);
    };
    50% {
      transform: translateX(-3px);
    };
    75% {
      transform: translateX(3px);
    };
    100% {
      transform: translateX(-3px);
    }
  }
}

@mixin shake2 {
  animation: shake2 0.3s 0.3s linear;
  @keyframes shake2 {
    0% {
      transform: translate(-3px,-50%);
    };
    25% {
      transform: translate(3px,-50%);
    };
    50% {
      transform: translate(-3px,-50%);
    };
    75% {
      transform: translate(3px,-50%);
    };
    100% {
      transform: translate(-3px,-50%);
    }
  }
}

@mixin buttonIn {
  animation: buttonIn .5s ease-out;
  @keyframes buttonIn {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(.9);
    }

    60% {
      transform: scale(.9);
    }

    100% {
      transform: scale(1);
    }
  }
}

@mixin rotate($params) {
  -webkit-transform: rotate($params);
     -moz-transform: rotate($params);
   -khtml-transform: rotate($params);
       -o-transform: rotate($params);
          transform: rotate($params);
}

@mixin opacity($opacity) {
  -webkit-opacity:$opacity;
     -moz-opacity:$opacity;
   -khtml-opacity:$opacity;
       -o-opacity:$opacity;
          opacity:$opacity;
}

@mixin transition($params) {
  -webkit-transition: $params;
     -moz-transition: $params;
   -khtml-transition: $params;
       -o-transition: $params;
          transition: $params;
}
@mixin warning {
  animation-name: warning;
  animation-duration: 400ms;
  animation-delay: var(--animation-order);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  @keyframes warning {
    0% {
      background: initial;
    }

    50% {
      background: rgba(var(--ion-color-danger-rgb), 20%);
    }

    100% {
      background: initial;
    }
  }
}

@mixin bp-1 {
  @media only screen and (max-width: #{$bp-1}) {
    @content;
  }
}

@mixin bp-xl {
    @media only screen and (max-width: #{$bp-xl}) {
        @content;
    }
}

@mixin bp-lg {
    @media only screen and (max-width: #{$bp-lg}) {
        @content;
    }
}

@mixin bp-md {
  @media only screen and (max-width: #{$bp-md}) {
      @content;
  }
}

@mixin bp-sm {
    @media only screen and (max-width: #{$bp-sm}) {
        @content;
    }
}

@mixin bp-xs {
    @media only screen and (max-width: #{$bp-xs}) {
        @content;
    }
}

/* Mixins */

@mixin container-styles {
  padding: 8px 12px;
  background: var(--ion-color-primary);
}

@mixin hover-styles {
  transition: all 0.2s ease;
  &:hover {
    background: $active-color;
  }
}

@mixin icon-close {
  position: absolute;
  top: -10px;
  right: 0;
  cursor: pointer;
  padding: 20px;
  margin: -20px;
}
