@import '../../assets/scss/scss-variables.scss';

/*--- Classes ------------*/
/*------- Colors ------*/
.success {
  color: var(--ion-color-success);
}

.success-dark {
  color: var(--ion-color-success-shade)
}

.warning {
  color: var(--ion-color-danger) !important;
}

.tertiary {
  color: var(--ion-color-tertiary)
}

.warning-yellow {
  color: var(--ion-color-warning);
}

.primary {
  color: var(--ion-color-primary);
}

.dark {
  color: var(--ion-color-dark);
}

.medium {
  color: var(--ion-color-medium);
}

.light-grey {
  color: $text-light-grey;
}

.bright-light-grey {
  color: $text-bright-light-grey;
}


.tint-grey {
  color: var(--ion-color-medium-tint)
}

.light {
  color: var(--ion-color-light);
}

.text-color-secondary {
  color: var(--ion-color-secondary);
}

.text-color-light-mode {
  color: var(--ion-text-color);
}

.neutral {
  color: var(--ion-color-medium);
}

.grayscale {
  filter: grayscale(1);
  // pointer-events: none;
}

//-------------BG Colors-----------------//
.modal-background {
  background-color: $modal-background;
}

//--------image-styles-----------------//
.generic-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 0 6px;
  background: rgba($color: var(--ion-color-tertiary-rgb), $alpha: 0.2);
}

//---------------border-styles------------//
.gradient-outline-border {
  position: relative;
  z-index: 1;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: var(--ion-color-primary);
    z-index: -1;
  }

  &:after {
    position: absolute;
    content: '';
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    transform: translateX(-2px);
    background: linear-gradient(to right, #6501FF, #0177FF);
    z-index: -2;
  }
}

.gradient-outline-border-hover {
  position: relative;
  z-index: 1;

  &:hover {
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: var(--ion-color-primary);
      z-index: -1;
    }

    &:after {
      position: absolute;
      content: '';
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      transform: translateX(-2px);
      background: linear-gradient(to right, #6501FF, #0177FF);
      z-index: -2;
    }
  }
}

.active-card-border {
  transition: all 0.1s ease;
  border-right: 4px solid var(--ion-color-primary);
  border-left: 4px solid var(--ion-color-primary);
  background: var(--ion-color-primary);

  &:hover {
    background: $active-color;
    border-right-color: var(--ion-color-tertiary);
  }

  &.active {
    background: $active-color;
    border-right-color: var(--ion-color-tertiary);
  }
}

//-------------Font Size-----------------//
.font-size-sm {
  font-size: $font-size-sm; //12px
}

.font-size-15 {
  font-size: $font-size-15 !important;
}

.font-size-18 {
  font-size: $font-size-18
}

.font-size-20 {
  font-size: $font-size-20;
}

.font-size-m {
  font-size: $font-size-m; //24px
}

.font-size-lg {
  font-size: $font-size-lg; //36px
}

.text-bold {
  font-weight: bold;
}


//-------------Layout/Sizing-----------------//
.text-center {
  text-align: center;
}
.main-title {
  font-size: $font-size-20;
  font-weight: bold;
  color: $white;
}

@media only screen and (min-width: 780px) {
  .icon-close {
    position: absolute;
    top: 18px;
    right: 22px;
    cursor: pointer;
    padding: 10px;
    margin: -20px;
    transition: color 100ms ease;
  }
}

.icon-close:hover {
  color: rgba(255, 255, 255, 0.7);
}
