@import './scss-variables.scss';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:focus-visible {
  outline: none;
}

input {
  background: transparent;
}

.generic-input {
  border: 1px solid $text-light-grey;
  padding: 6px;
  color: $white;
  min-height: 35px;

  &:focus {

    &::placeholder {
      opacity: 0;
    }
  }

  &::placeholder {
    color: $text-light-grey;
    font-size: 14px;
  }

  &.dark {
    background: var(--ion-color-primary-shade);
  }

  &.error {
    border: 2px solid var(--ion-color-danger);
  }
}
